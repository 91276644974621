import { getUsedArtists } from "../../routes/distribution";
import { getUserSales, adminGetAllUserSales } from "../../routes/sales";
import { REDUX_ADD_ALERT } from "./appActions";

export const EDIT_RELEASE_FIELD = "EDIT_RELEASE_FIELD";
export const POPULATE_RELEASE_FORM = "POPULATE_RELEASE_FORM";

export const ADD_RELEASE_PRIMARY_ARTIST_FIELD =
  "ADD_RELEASE_PRIMARY_ARTIST_FIELD";
export const ADD_RELEASE_CONTRIBUTOR_FIELD = "ADD_RELEASE_CONTRIBUTOR_FIELD";
export const REMOVE_RELEASE_PRIMARY_ARTIST_FIELD =
  "REMOVE_RELEASE_PRIMARY_ARTIST_FIELD";
export const REMOVE_RELEASE_CONTRIBUTOR_FIELD =
  "REMOVE_RELEASE_CONTRIBUTOR_FIELD";

export const POPULATE_TRACKS_FILES = "POPULATE_TRACKS_FILES";
export const POPULATE_TRACKS_FORM = "POPULATE_TRACKS_FORM";
export const UPDATE_TRACK_TYPE = "UPDATE_TRACK_TYPE";
export const POPULATE_CURRENT_TRACK_FORM = "POPULATE_CURRENT_TRACK_FORM";
export const ADD_AND_PREPOPULATE_TRACK_WITH_BASIC_INFORMATION =
  "ADD_AND_PREPOPULATE_TRACK_WITH_BASIC_INFORMATION";

export const UPDATE_FORM_WITH_TRACK_LICENSE_FILE = "UPLOAD_TRACK_LICENSE_FILE";
export const REMOVE_TRACK_LICENSE_FILE = "REMOVE_TRACK_LICENSE_FILE";

export const SET_UPLOADING_IN_PROGRESS = "SET_UPLOADING_IN_PROGRESS";

export const REMOVE_TRACK = "REMOVE_TRACK";

export const EDIT_CURRENT_TRACK_FIELD = "EDIT_CURRENT_TRACK_FIELD";
export const EDIT_CURRENT_TRACK = "EDIT_CURRENT_TRACK";
export const TOGGLE_EXPAND_TRACK_PANEL = "TOGGLE_EXPAND_TRACK_PANEL";
export const CLOSE_TRACK_PANEL = "CLOSE_TRACK_PANEL";
export const CLOSE_OTHER_TRACK_PANELS = "CLOSE_OTHER_TRACK_PANELS";

export const ADD_UPLOAD_TRACKS_PRIMARY_ARTIST_FIELD =
  "ADD_UPLOAD_TRACKS_PRIMARY_ARTIST_FIELD";
export const ADD_UPLOAD_TRACKS_CONTRIBUTOR_FIELD =
  "ADD_UPLOAD_TRACKS_CONTRIBUTOR_FIELD";
export const REMOVE_UPLOAD_TRACKS_PRIMARY_ARTIST_FIELD =
  "REMOVE_UPLOAD_TRACKS_PRIMARY_ARTIST_FIELD";
export const REMOVE_UPLOAD_TRACKS_CONTRIBUTOR_FIELD =
  "REMOVE_UPLOAD_TRACKS_CONTRIBUTOR_FIELD";

export const VALIDATE_UT_FIELD = "VALIDATE_UT_FIELD";
export const VALIDATE_ALL_TRACKS = "VALIDATE_ALL_TRACKS";

export const TOGGLE_UT_LICENSE_CONFIRM_CHECKBOX =
  "TOGGLE_UT_LICENSE_CONFIRM_CHECKBOX";

export const AUTOFIX_SINGLE = "AUTOFIX_SINGLE";

export const CLEAN_UP_SUBMITTING = "CLEAN_UP_SUBMITTING";

export const POPULATE_USER_EMAIL = "POPULATE_USER_EMAIL";

// subscription rules
export const SET_USED_ARTIST_FIELDS = "SET_USED_ARTIST_FIELDS";
export const LOCK_FORM_FIELD = "LOCK_FORM_FIELD";

// sales
export const SET_USER_SALES = "SET_USER_SALES";

// release

export function editReleaseField(
  value,
  databaseField,
  databaseKey,
  subField,
  inputId
) {
  return {
    type: EDIT_RELEASE_FIELD,
    databaseField: databaseField,
    databaseKey: databaseKey,
    subField: subField,
    inputId: inputId,
    value: value,
  };
}

export function populateReleaseForm(releaseForm) {
  return {
    type: POPULATE_RELEASE_FORM,
    releaseForm: releaseForm,
  };
}

export function addReleasePrimaryArtistField() {
  return {
    type: ADD_RELEASE_PRIMARY_ARTIST_FIELD,
  };
}

export function addReleaseContributorField() {
  return {
    type: ADD_RELEASE_CONTRIBUTOR_FIELD,
  };
}

export function removeReleasePrimaryArtistField(inputId) {
  return {
    type: REMOVE_RELEASE_PRIMARY_ARTIST_FIELD,
    inputId: inputId,
  };
}

export function removeReleaseContributorField(inputId) {
  return {
    type: REMOVE_RELEASE_CONTRIBUTOR_FIELD,
    inputId: inputId,
  };
}

// track

export function populateTracksFiles(tracksFiles) {
  return {
    type: POPULATE_TRACKS_FILES,
    tracksFiles: tracksFiles,
  };
}

export function populateTracksForm(tracksForm) {
  return {
    type: POPULATE_TRACKS_FORM,
    tracksForm: tracksForm,
  };
}

export const updateTrackType = (index, value) => {
  return {
    type: UPDATE_TRACK_TYPE,
    payload: { index, value },
  };
};

export function populateUserEmail(userEmail) {
  return {
    type: POPULATE_USER_EMAIL,
    userEmail: userEmail,
  };
}

export function populateCurrentTrackForm(tracksForm) {
  return {
    type: POPULATE_CURRENT_TRACK_FORM,
    tracksForm: tracksForm,
  };
}

export function editCurrentTrackField(
  value,
  databaseField,
  trackIndex,
  databaseKey,
  inputId,
  subKey
) {
  return {
    type: EDIT_CURRENT_TRACK_FIELD,
    value: value,
    databaseField: databaseField,
    trackIndex: trackIndex,
    databaseKey: databaseKey,
    inputId: inputId,
    subKey: subKey,
  };
}

export function editCurrentTrack(value, trackIndex) {
  return {
    type: EDIT_CURRENT_TRACK,
    value: value,
    trackIndex: trackIndex,
  };
}

export function toggleExpandTrackPanel(trackIndex) {
  return {
    type: TOGGLE_EXPAND_TRACK_PANEL,
    trackIndex: trackIndex,
  };
}

export function closeTrackPanel(trackIndex) {
  return {
    type: CLOSE_TRACK_PANEL,
    trackIndex: trackIndex,
  };
}

export function closeOtherTrackPanels(trackIndex) {
  return {
    type: CLOSE_OTHER_TRACK_PANELS,
    trackIndex: trackIndex,
  };
}

export function addUploadTracksPrimaryArtistField(trackIndex) {
  return {
    type: ADD_UPLOAD_TRACKS_PRIMARY_ARTIST_FIELD,
    trackIndex: trackIndex,
  };
}

export function addUploadTracksContributorField(trackIndex) {
  return {
    type: ADD_UPLOAD_TRACKS_CONTRIBUTOR_FIELD,
    trackIndex: trackIndex,
  };
}

export function removeUploadTracksPrimaryArtistField(trackIndex, inputId) {
  return {
    type: REMOVE_UPLOAD_TRACKS_PRIMARY_ARTIST_FIELD,
    trackIndex: trackIndex,
    inputId: inputId,
  };
}

export function removeUploadTracksContributorField(trackIndex, inputId) {
  return {
    type: REMOVE_UPLOAD_TRACKS_CONTRIBUTOR_FIELD,
    trackIndex: trackIndex,
    inputId: inputId,
  };
}

export function addAndPrepopulateTrackWithBasicInformation(
  originalFileName,
  wavFileLink
) {
  return {
    type: ADD_AND_PREPOPULATE_TRACK_WITH_BASIC_INFORMATION,
    originalFileName: originalFileName,
    wavFileLink: wavFileLink,
  };
}

export function updateFormWithTrackLicenseFile(
  trackIndex,
  inputIndex,
  originalFileName,
  licenseFileUrl
) {
  return {
    type: UPDATE_FORM_WITH_TRACK_LICENSE_FILE,
    trackIndex: trackIndex,
    inputIndex: inputIndex,
    originalFileName: originalFileName,
    licenseFileUrl: licenseFileUrl,
  };
}

export function removeTrackLicenseFile(trackIndex, inputIndex) {
  return {
    type: REMOVE_TRACK_LICENSE_FILE,
    trackIndex: trackIndex,
    inputIndex: inputIndex,
  };
}

export function setUploadingInProgress(value) {
  return {
    type: SET_UPLOADING_IN_PROGRESS,
    value: value,
  };
}

export function removeTrack(trackIndex) {
  return {
    type: REMOVE_TRACK,
    trackIndex: trackIndex,
  };
}

export function validateAllTracks() {
  return {
    type: VALIDATE_ALL_TRACKS,
  };
}

export function toggleUtLicensesConfirmCheckbox() {
  return {
    type: TOGGLE_UT_LICENSE_CONFIRM_CHECKBOX,
  };
}

export function autofixSingle() {
  return {
    type: AUTOFIX_SINGLE,
  };
}

export function validateUTField(
  value,
  databaseField,
  databaseKey,
  databaseSubKey,
  inputId,
  trackIndex
) {
  return {
    type: VALIDATE_UT_FIELD,
    value: value,
    databaseField: databaseField,
    databaseKey: databaseKey,
    databaseSubKey: databaseSubKey,
    inputId: inputId,
    trackIndex: trackIndex,
  };
}

export function cleanUpSubmitting() {
  return {
    type: CLEAN_UP_SUBMITTING,
  };
}

export function lockFormField(databaseField, databaseKey, subKey, inputId) {
  return {
    type: LOCK_FORM_FIELD,
    lockedField: {
      databaseField: databaseField,
      databaseKey: databaseKey,
      subKey: subKey,
      inputId: inputId,
    },
  };
}

export function setUsedArtistFields() {
  return async (dispatch, getState) => {
    try {
      const usedArtistsResponse = await getUsedArtists();
      dispatch({
        type: SET_USED_ARTIST_FIELDS,
        fields: usedArtistsResponse.data.used_artists,
        slots: usedArtistsResponse.data.artist_slots,
      });
    } catch (e) {
      console.error(e);
    }
  };
}

export function setUserSales() {
  return async (dispatch, getState) => {
    try {
      const userSalesResponse = await getUserSales();
      dispatch({
        type: SET_USER_SALES,
        data: userSalesResponse?.data,
      });
    } catch (e) {
      console.error(e);
    }
  };
}

export function adminSetAllUserSales() {
  return async (dispatch, getState) => {
    try {
      const allUsersSalesResponse = await adminGetAllUserSales();
      dispatch({
        type: SET_USER_SALES,
        data: allUsersSalesResponse?.data || [],
      });
    } catch (e) {
      console.error(e);
    }
  };
}
