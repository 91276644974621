import {
  valUpc,
  valCnum,
  valYear,
  valDigRel,
  valOrRel,
  valCover,
  valIsrc,
  valIswc,
  valEmail,
  valShare,
  val,
  valSecondaryGenre,
  valTitle,
  valVersion,
  valGeneralShareAmount,
  valContributors,
  trackValContributors,
} from "./distrValidationHelpers";

import { isValidHttpUrl } from "../../utils";

const FREE_PLAN = "playtreks_free";

// validation for basic information page
export function validateBI(
  value,
  databaseField,
  inputId,
  checked,
  valMessages,
  setValMessages,
  form,
  prSwitch,
  validation,
  setValidation,
  reduxCurrentPlan,
  databaseKey
) {
  if (databaseField) {
    // for validating individual fields
    var valueIsCorrect;

    if (databaseField === "#upc") {
      valueIsCorrect = valUpc(value, checked);
    } else if (databaseField === "#c_year" || databaseField === "#p_year") {
      valueIsCorrect = valYear(value);
    } else if (databaseField === "#catalog_number") {
      valueIsCorrect = valCnum(value, checked);
    } else if (databaseField === "#digital_release") {
      valueIsCorrect = valDigRel(value, valMessages, setValMessages, form);
    } else if (databaseField === "#original_release") {
      valueIsCorrect = valOrRel(
        value,
        prSwitch,
        valMessages,
        setValMessages,
        form
      );
    } else if (databaseField === "#cover_url") {
      valueIsCorrect = valCover(value);
    } else if (databaseField === "#title") {
      valueIsCorrect = valTitle(value);
    } else if (databaseField === "#remix_or_version") {
      valueIsCorrect = valVersion(value);
    } else if (databaseField === "#secondary_genre") {
      valueIsCorrect = valSecondaryGenre(value, form["#primary_genre"]);
    } else if (databaseField === "#participants") {
      const { primary, contributors } = form["#participants"];

      if (databaseKey === "contributors") {
        valueIsCorrect = valContributors(
          contributors,
          primary,
          valMessages,
          setValMessages
        );
      } else {
        valueIsCorrect = val(form["#participants"].primary[0].primary);
      }
    } else {
      valueIsCorrect = val(value);
    }

    // get the valueIsCorrect variable and validate
    if (databaseField === "#participants") {
      setValidation({
        ...validation,
        [`${databaseField}.${databaseKey}`]: valueIsCorrect ? false : true,
      });
    } else {
      setValidation({
        ...validation,
        [databaseField]: valueIsCorrect ? false : true,
      });
    }
  } else {
    const { primary, contributors } = form["#participants"];

    // for validating all fields on submit
    let validationObject = {
      "#title": !valTitle(form["#title"]),
      "#remix_or_version": !valVersion(form["#remix_or_version"]),
      "#primary_genre": !val(form["#primary_genre"]),
      "#secondary_genre": !valSecondaryGenre(
        form["#secondary_genre"],
        form["#primary_genre"]
      ), // checks if the two genres are not the same
      "#participants.primary": !val(form["#participants"].primary[0].primary), // in case of validation this field corresponds the first field for primary artists
      "#participants.contributors": !valContributors(
        contributors,
        primary,
        valMessages,
        setValMessages
      ),
      "#language": !val(form["#language"]),
      "#upc": !valUpc(form["#upc"], checked),
      "#catalog_number": !valCnum(form["#catalog_number"], checked),
      "#digital_release": !valDigRel(
        form["#digital_release"],
        valMessages,
        setValMessages,
        form
      ),
      "#original_release": !valOrRel(
        form["#original_release"],
        prSwitch,
        valMessages,
        setValMessages,
        form
      ),
      "#license_type": !val(form["#license_type"]),
      "#p_line": !val(form["#p_line"]),
      "#p_year": !valYear(form["#p_year"]),
      "#c_line": !val(form["#c_line"]),
      "#c_year": !valYear(form["#c_year"]),
      "#cover_url": !valCover(form["#cover_url"]),
      socialMediaLinks:
        !isValidHttpUrl(form.socialMediaLink1) &&
        !isValidHttpUrl(form.socialMediaLink2) &&
        !isValidHttpUrl(form.socialMediaLink3) &&
        reduxCurrentPlan == FREE_PLAN,
    };

    setValidation(validationObject);
    return Object.entries(validationObject)
      .map((v) => {
        return v[1];
      })
      .join(",")
      .includes("true");
    // returns true if validation rejection present
  }
}

export function validateAllUT(tracksForm, releaseForm) {
  return tracksForm.map((trackform) => {
    // for validating all fields on submit
    let form = trackform.form;
    const { contributors } = form["#participants"];
    const trackType = trackform.trackType;

    // checking the sum of all shares (if shares present, they should not exceed 100%)
    let generalShareAmount =
      Object.values(
        form["#participants"]?.primary
          .map((item) => item.share)
          .concat(
            form?.["#participants"].contributors.map((item) => item.share)
          )
      ).reduce(
        (accumulator, currentValue) =>
          Number(accumulator) + Number(currentValue)
      ) || 0;

    let validationObject = {
      "#track_title": !valTitle(form["#track_title"]),
      "#remix_or_version": !valVersion(form["#remix_or_version"]),
      "#secondary_genre": !valSecondaryGenre(
        form["#secondary_genre"],
        form["#primary_genre"]
      ),
      "#participants": {
        name: !val(form["#participants"].primary?.[0].name),
      }, // in case of validation this field corresponds the first field for primary artists
      "#participants.contributors": !trackValContributors(
        contributors,
        trackType
      ),
      "#language": !val(form["#language"]),
      "#p_line": !val(form["#p_line"]),
      "#p_year": !valYear(form["#p_year"]),
      "#isrc": !valIsrc(form["#isrc"]),
      "#audio_url": !val(trackform["#audio_url"]),
    };

    const releasePrimaryArtists = releaseForm?.["#participants"]?.primary?.map(
      (artist) => artist.primary
    );
    const trackPrimaryArtists = form?.["#participants"]?.primary?.map(
      (artist) => artist.name
    );
    const releaseOnePrimaryArtistPresentValidation =
      !releasePrimaryArtists.some((r) => trackPrimaryArtists.includes(r));

    // setValidation(validationObject);
    return Object.assign(validationObject, {
      __allFieldsBoolean: Object.entries(validationObject)
        .map((v) => v[1])
        .join(",")
        .includes("true"), // returns true if validation rejection present (not passing)
      __generalShareAmountValidation: valGeneralShareAmount(generalShareAmount), // returns true if share amount validation present
      __releaseOnePrimaryArtistPresentValidation:
        releaseOnePrimaryArtistPresentValidation,
    });
  });
}

export function validateUT(
  value,
  databaseField,
  databaseKey,
  databaseSubKey,
  inputId,
  form,
  track
) {
  let validation = {};
  // TODO: single field val
  // for validating individual fields
  var valueIsCorrect;
  if (databaseField === "#isrc") {
    valueIsCorrect = valIsrc(value);
  } else if (databaseField === "#iswc") {
    valueIsCorrect = valIswc(value);
  } else if (databaseField === "#c_year" || databaseField === "#p_year") {
    valueIsCorrect = valYear(value);
  } else if (databaseSubKey === "email") {
    valueIsCorrect = valEmail(value, databaseKey, inputId);
  } else if (databaseSubKey === "share") {
    valueIsCorrect = valShare(value);
  } else if (databaseField === "#track_title") {
    valueIsCorrect = valTitle(value);
  } else if (databaseField === "#remix_or_version") {
    valueIsCorrect = valVersion(value);
  } else if (databaseField === "#secondary_genre") {
    valueIsCorrect = valSecondaryGenre(value, form["#primary_genre"]);
  } else if (databaseField === "#participants.contributors") {
    valueIsCorrect = trackValContributors(
      form["#participants"]?.contributors,
      track?.trackType
    );
  } else {
    valueIsCorrect = val(value);
  }
  return !valueIsCorrect;
}
