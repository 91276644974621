export const tooltipData = {
  artistSlots:
    "Please note: collaborations take up an artist slot, <br/> even if one of the artists is already being used on your account. <br/> If you need more artist slots, you can upgrade your account.",
  upc: "The barcode (UPC-EAN13) <br/> is used to identify your release <br/> in the distribution process and to track sales. <br/> It is obligatory for distributing your release. <br/><br/> If you don't have this code, we will assign one to your release.",
  catalogNumber:
    "Catalog numbers can only contain 1 to 45 alphanumeric characters.<br/> If you do not have a catalog number for this release, <br/> you can request assignation of a new code <br/> by checking the checkbox below",
  digitalRelease:
    "Select the date when you want<br/>your release to be available for digital sale.<br/>Take into account that we deliver your release<br/>within 24 hours to the channels you select,<br/>but the actual distribution can take between<br/>one and two weeks depending<br/>on the stores you choose.<br/>You can only select a date seven days from today,<br/>but we strongly suggest a distribution<br/>period of 14 days or more.",
  originalRelease:
    "This is the date that the album was first released. <br/> This will not affect the date that your music is available for sale.",
  licenseType:
    "Indicate the type of license that is applicable to your release",
  licenseInfoType: "Select the applicable type of Creative Commons license",
  licenseHolder:
    "The © line is for the composition copyright holder(s): <br/> the person(s) who originally created the music composition <br/> (the melody, lyrics, song structure).",
  trackVersion:
    "To differentiate between multiple versions of the same track <br/> or indicate that a track is different from its original version, <br/> use the corresponding description in its version field. <br/> For example: Radio, Extended, Remix...",
  artistShare: "Assing the percentage share of the contributor",
  contributorsShare: "Assign the percentage share of the contributor",
  isrc: "The ISRC is a unique code used to identify your track <br/> in the distribution process and to track sales. <br/> It is obligatory for distributing your release. <br/> If you don't have this code, we will assign one to your track. <br/> If you do provide one, please use the correct <br/> 12 character format LL-LLL-NN-NNNNN ('-' are optional) <br/> * L - letter, N - number",
  iswc: "International Standard Musical Work Code (ISWC) <br/> is a unique identifier for musical works. <br/> If you provide an ISWC, make sure you also fill out <br/> the authors and composers in the Artist(s) field above.",
  copyright:
    "The ℗ line is for the phonographic copyright holder - the person or organisation <br/>(like a label) who owns the final sound recording, <br/> or who obtained a license to exploit the final sound recording.",
  releaseType:
    "Based on number of tracks: <br/> 1-3 tracks - Single <br/> 4-6 tracks - EP <br/> 7+ tracks - Album",
  uploadTrackPageGenre:
    "The track genre corresponds the genre for the release. <br/> In order to change it, please navigate back to the Album Details page <br/> using the colored circles on top of the page.",
  language:
    'The language at the release level corresponds to the metadata language (e.g., title, etc.). <br/> The language at the track level indicates the language of the lyrics. <br/> If the track is without lyrics, please select "this track is instrumental" in the next step.',
};

export const genres = [
  { value: "Alternative", label: "Alternative" },
  { value: "Alternative/Experimental", label: "Alternative/Experimental" },
  { value: "Alternative/Gothic", label: "Alternative/Gothic" },
  { value: "Alternative/Grunge", label: "Alternative/Grunge" },
  { value: "Alternative/Indie Pop", label: "Alternative/Indie Pop" },
  { value: "Alternative/Indie Rock", label: "Alternative/Indie Rock" },
  { value: "Alternative/Rock", label: "Alternative/Rock" },
  { value: "Ambient/New Age", label: "Ambient/New Age" },
  { value: "Ambient/New Age/Meditation", label: "Ambient/New Age/Meditation" },
  { value: "Blues", label: "Blues" },
  { value: "Blues/Contemporary Blues", label: "Blues/Contemporary Blues" },
  { value: "Blues/New Orleans Blues", label: "Blues/New Orleans Blues" },
  { value: "Blues/Traditional Blues", label: "Blues/Traditional Blues" },
  { value: "Children's Music", label: "Children's Music" },
  { value: "Children's Music/Classic", label: "Children's Music/Classic" },
  { value: "Children's Music/Holiday", label: "Children's Music/Holiday" },
  { value: "Children's Music/Stories", label: "Children's Music/Stories" },
  { value: "Classical", label: "Classical" },
  { value: "Classical/Antique", label: "Classical/Antique" },
  { value: "Classical/Baroque", label: "Classical/Baroque" },
  { value: "Classical/Chamber", label: "Classical/Chamber" },
  { value: "Classical/Concert", label: "Classical/Concert" },
  {
    value: "Classical/Modern Compositions",
    label: "Classical/Modern Compositions",
  },
  { value: "Classical/Opera", label: "Classical/Opera" },
  { value: "Classical/Orchestral", label: "Classical/Orchestral" },
  { value: "Classical/Piano", label: "Classical/Piano" },
  { value: "Classical/Romantic", label: "Classical/Romantic" },
  { value: "Comedy", label: "Comedy" },
  { value: "Country", label: "Country" },
  { value: "Country/Bluegrass", label: "Country/Bluegrass" },
  { value: "Country/Contemporary", label: "Country/Contemporary" },
  { value: "Country/Honky Tonk", label: "Country/Honky Tonk" },
  { value: "Country/Nashville", label: "Country/Nashville" },
  { value: "Country/Pop", label: "Country/Pop" },
  { value: "Country/Square Dance", label: "Country/Square Dance" },
  { value: "Easy Listening", label: "Easy Listening" },
  {
    value: "Easy Listening/Bar Jazz/Cocktail",
    label: "Easy Listening/Bar Jazz/Cocktail",
  },
  { value: "Easy Listening/Bossa Nova", label: "Easy Listening/Bossa Nova" },
  { value: "Easy Listening/Lounge", label: "Easy Listening/Lounge" },
  { value: "Easy Listening/Traditional", label: "Easy Listening/Traditional" },
  { value: "Electronic", label: "Electronic" },
  { value: "Electronic/Acid House", label: "Electronic/Acid House" },
  { value: "Electronic/Breaks", label: "Electronic/Breaks" },
  { value: "Electronic/Broken beat", label: "Electronic/Broken beat" },
  { value: "Electronic/Chill Out", label: "Electronic/Chill Out" },
  {
    value: "Electronic/DJ Tools/Sample Packs",
    label: "Electronic/DJ Tools/Sample Packs",
  },
  { value: "Electronic/Dance", label: "Electronic/Dance" },
  { value: "Electronic/Deep House", label: "Electronic/Deep House" },
  {
    value: "Electronic/Downtempo - experimental",
    label: "Electronic/Downtempo - experimental",
  },
  { value: "Electronic/Drum & Bass", label: "Electronic/Drum & Bass" },
  {
    value: "Electronic/Dub/Reggae/Dancehall",
    label: "Electronic/Dub/Reggae/Dancehall",
  },
  { value: "Electronic/Dubstep/Grime", label: "Electronic/Dubstep/Grime" },
  { value: "Electronic/Electro House", label: "Electronic/Electro House" },
  { value: "Electronic/Glitch Hop", label: "Electronic/Glitch Hop" },
  { value: "Electronic/Hard Dance", label: "Electronic/Hard Dance" },
  { value: "Electronic/Hard Techno", label: "Electronic/Hard Techno" },
  { value: "Electronic/Hardcore", label: "Electronic/Hardcore" },
  { value: "Electronic/Hardstyle", label: "Electronic/Hardstyle" },
  { value: "Electronic/House", label: "Electronic/House" },
  {
    value: "Electronic/Indie Dance/Nu Disco",
    label: "Electronic/Indie Dance/Nu Disco",
  },
  { value: "Electronic/Jazz", label: "Electronic/Jazz" },
  { value: "Electronic/Minimal", label: "Electronic/Minimal" },
  { value: "Electronic/Pop Trance", label: "Electronic/Pop Trance" },
  {
    value: "Electronic/Progressive House",
    label: "Electronic/Progressive House",
  },
  { value: "Electronic/Psy-Trance", label: "Electronic/Psy-Trance" },
  { value: "Electronic/Tech House", label: "Electronic/Tech House" },
  { value: "Electronic/Techno", label: "Electronic/Techno" },
  { value: "Electronic/Trance", label: "Electronic/Trance" },
  { value: "Electronic/Trip Hop", label: "Electronic/Trip Hop" },
  { value: "Experimental", label: "Experimental" },
  { value: "Fitness&Workout", label: "Fitness&Workout" },
  { value: "Flamenco", label: "Flamenco" },
  { value: "Folk", label: "Folk" },
  { value: "Funk/R&B", label: "Funk/R&B" },
  { value: "Hip-Hop/Rap", label: "Hip-Hop/Rap" },
  {
    value: "Hip-Hop/Rap/Gangsta & Hardcore",
    label: "Hip-Hop/Rap/Gangsta & Hardcore",
  },
  { value: "Holiday/Christmas", label: "Holiday/Christmas" },
  { value: "Inspirational", label: "Inspirational" },
  { value: "Jazz", label: "Jazz" },
  { value: "Jazz/Bebop", label: "Jazz/Bebop" },
  { value: "Jazz/Big Band", label: "Jazz/Big Band" },
  { value: "Jazz/Brazilian Jazz", label: "Jazz/Brazilian Jazz" },
  { value: "Jazz/Classic", label: "Jazz/Classic" },
  { value: "Jazz/Contemporary", label: "Jazz/Contemporary" },
  { value: "Jazz/Dixie/Rag Time", label: "Jazz/Dixie/Rag Time" },
  { value: "Jazz/Free Jazz", label: "Jazz/Free Jazz" },
  { value: "Jazz/Fusion", label: "Jazz/Fusion" },
  { value: "Jazz/Jazz Funk", label: "Jazz/Jazz Funk" },
  { value: "Jazz/Latin Jazz", label: "Jazz/Latin Jazz" },
  { value: "Jazz/Nu Jazz/Acid Jazz", label: "Jazz/Nu Jazz/Acid Jazz" },
  { value: "Jazz/Smooth Jazz", label: "Jazz/Smooth Jazz" },
  { value: "Jazz/Swing", label: "Jazz/Swing" },
  { value: "Jazz/Traditional", label: "Jazz/Traditional" },
  { value: "Jazz/World", label: "Jazz/World" },
  { value: "Karaoke", label: "Karaoke" },
  { value: "Latin", label: "Latin" },
  { value: "Latin/Bachata", label: "Latin/Bachata" },
  { value: "Latin/Banda", label: "Latin/Banda" },
  { value: "Latin/Big Band", label: "Latin/Big Band" },
  { value: "Latin/Bolero", label: "Latin/Bolero" },
  { value: "Latin/Bossa Nova", label: "Latin/Bossa Nova" },
  { value: "Latin/Brasil/Tropical", label: "Latin/Brasil/Tropical" },
  { value: "Latin/Christian", label: "Latin/Christian" },
  { value: "Latin/Conjunto", label: "Latin/Conjunto" },
  { value: "Latin/Corridos", label: "Latin/Corridos" },
  { value: "Latin/Cuban", label: "Latin/Cuban" },
  { value: "Latin/Cumbia", label: "Latin/Cumbia" },
  { value: "Latin/Duranguense", label: "Latin/Duranguense" },
  { value: "Latin/Electronica", label: "Latin/Electronica" },
  { value: "Latin/Grupero", label: "Latin/Grupero" },
  { value: "Latin/Hip Hop", label: "Latin/Hip Hop" },
  { value: "Latin/Latin Rap", label: "Latin/Latin Rap" },
  { value: "Latin/Mambo", label: "Latin/Mambo" },
  { value: "Latin/Mariachi", label: "Latin/Mariachi" },
  { value: "Latin/Norteño", label: "Latin/Norteño" },
  { value: "Latin/Pop", label: "Latin/Pop" },
  { value: "Latin/Ranchera", label: "Latin/Ranchera" },
  { value: "Latin/Reggaeton", label: "Latin/Reggaeton" },
  { value: "Latin/Regional Mexicano", label: "Latin/Regional Mexicano" },
  { value: "Latin/Rock en Español", label: "Latin/Rock en Español" },
  { value: "Latin/Salsa", label: "Latin/Salsa" },
  { value: "Latin/Salsa/Merengue", label: "Latin/Salsa/Merengue" },
  { value: "Latin/Sierreño", label: "Latin/Sierreño" },
  { value: "Latin/Sonidero", label: "Latin/Sonidero" },
  { value: "Latin/Tango", label: "Latin/Tango" },
  { value: "Latin/Tejano", label: "Latin/Tejano" },
  { value: "Latin/Tierra Caliente", label: "Latin/Tierra Caliente" },
  { value: "Latin/Traditional Mexican", label: "Latin/Traditional Mexican" },
  { value: "Latin/Vallenato", label: "Latin/Vallenato" },
  { value: "New Age", label: "New Age" },
  { value: "Pop", label: "Pop" },
  { value: "Pop/Contemporary/Adult", label: "Pop/Contemporary/Adult" },
  { value: "Pop/J-Pop", label: "Pop/J-Pop" },
  { value: "Pop/K-Pop", label: "Pop/K-Pop" },
  { value: "Pop/Mandopop", label: "Pop/Mandopop" },
  { value: "Pop/Singer Songwriter", label: "Pop/Singer Songwriter" },
  { value: "Punk", label: "Punk" },
  { value: "R&B", label: "R&B" },
  { value: "Reggae", label: "Reggae" },
  { value: "Rock", label: "Rock" },
  { value: "Rock/Brit-Pop", label: "Rock/Brit-Pop" },
  { value: "Rock/Classic", label: "Rock/Classic" },
  { value: "Rock/Glam Rock", label: "Rock/Glam Rock" },
  { value: "Rock/Hard Rock/Heavy Metal", label: "Rock/Hard Rock/Heavy Metal" },
  { value: "Rock/Heavy Metal", label: "Rock/Heavy Metal" },
  { value: "Rock/Progressive", label: "Rock/Progressive" },
  { value: "Rock/Rock 'n' Roll", label: "Rock/Rock 'n' Roll" },
  { value: "Rock/Singer/Songwriter", label: "Rock/Singer/Songwriter" },
  { value: "Ska", label: "Ska" },
  { value: "Soul", label: "Soul" },
  { value: "Soundtrack", label: "Soundtrack" },
  { value: "Soundtrack/Anime", label: "Soundtrack/Anime" },
  { value: "Soundtrack/Musical", label: "Soundtrack/Musical" },
  { value: "Soundtrack/TV", label: "Soundtrack/TV" },
  { value: "Spiritual", label: "Spiritual" },
  { value: "Spiritual/Christian", label: "Spiritual/Christian" },
  { value: "Spiritual/Gospel", label: "Spiritual/Gospel" },
  { value: "Spiritual/Gregorian", label: "Spiritual/Gregorian" },
  { value: "Spiritual/India", label: "Spiritual/India" },
  { value: "Spiritual/Judaica", label: "Spiritual/Judaica" },
  { value: "Spiritual/World", label: "Spiritual/World" },
  { value: "Spoken Word/Speeches", label: "Spoken Word/Speeches" },
  { value: "Trap", label: "Trap" },
  { value: "Trap/Future Bass", label: "Trap/Future Bass" },
  { value: "Trap/Future Bass/Twerk", label: "Trap/Future Bass/Twerk" },
  { value: "Vocal/Nostalgia", label: "Vocal/Nostalgia" },
  { value: "World", label: "World" },
  { value: "World/African", label: "World/African" },
  { value: "World/Afro-Beat", label: "World/Afro-Beat" },
  { value: "World/Afro-Pop", label: "World/Afro-Pop" },
  { value: "World/Americas/Argentina", label: "World/Americas/Argentina" },
  { value: "World/Americas/Brazilian", label: "World/Americas/Brazilian" },
  {
    value: "World/Americas/Brazilian/Axé",
    label: "World/Americas/Brazilian/Axé",
  },
  {
    value: "World/Americas/Brazilian/Black Music",
    label: "World/Americas/Brazilian/Black Music",
  },
  {
    value: "World/Americas/Brazilian/Bossa Nova",
    label: "World/Americas/Brazilian/Bossa Nova",
  },
  {
    value: "World/Americas/Brazilian/Chorinho",
    label: "World/Americas/Brazilian/Chorinho",
  },
  {
    value: "World/Americas/Brazilian/Folk",
    label: "World/Americas/Brazilian/Folk",
  },
  {
    value: "World/Americas/Brazilian/Funk Carioca",
    label: "World/Americas/Brazilian/Funk Carioca",
  },
  {
    value: "World/Americas/Brazilian/MPB",
    label: "World/Americas/Brazilian/MPB",
  },
  {
    value: "World/Americas/Brazilian/Marchinha",
    label: "World/Americas/Brazilian/Marchinha",
  },
  {
    value: "World/Americas/Brazilian/Pagode",
    label: "World/Americas/Brazilian/Pagode",
  },
  {
    value: "World/Americas/Brazilian/Samba",
    label: "World/Americas/Brazilian/Samba",
  },
  {
    value: "World/Americas/Brazilian/Samba-Rock",
    label: "World/Americas/Brazilian/Samba-Rock",
  },
  {
    value: "World/Americas/Brazilian/Samba-de-Raiz",
    label: "World/Americas/Brazilian/Samba-de-Raiz",
  },
  {
    value: "World/Americas/Brazilian/Samba-enredo",
    label: "World/Americas/Brazilian/Samba-enredo",
  },
  {
    value: "World/Americas/Brazilian/Sambalanço",
    label: "World/Americas/Brazilian/Sambalanço",
  },
  {
    value: "World/Americas/Cajun-Creole",
    label: "World/Americas/Cajun-Creole",
  },
  { value: "World/Americas/Calypso", label: "World/Americas/Calypso" },
  { value: "World/Americas/Colombia", label: "World/Americas/Colombia" },
  {
    value: "World/Americas/Cuba-Caribbean",
    label: "World/Americas/Cuba-Caribbean",
  },
  { value: "World/Americas/Mexican", label: "World/Americas/Mexican" },
  {
    value: "World/Americas/North-American",
    label: "World/Americas/North-American",
  },
  { value: "World/Americas/Panama", label: "World/Americas/Panama" },
  { value: "World/Americas/Peru", label: "World/Americas/Peru" },
  {
    value: "World/Americas/South-American",
    label: "World/Americas/South-American",
  },
  { value: "World/Arabic", label: "World/Arabic" },
  { value: "World/Asian/Central Asia", label: "World/Asian/Central Asia" },
  { value: "World/Asian/China", label: "World/Asian/China" },
  { value: "World/Asian/India", label: "World/Asian/India" },
  {
    value: "World/Asian/India-Bollywood",
    label: "World/Asian/India-Bollywood",
  },
  { value: "World/Asian/Japan", label: "World/Asian/Japan" },
  { value: "World/Asian/South Asia", label: "World/Asian/South Asia" },
  { value: "World/Australian/Pacific", label: "World/Australian/Pacific" },
  { value: "World/Ethnic", label: "World/Ethnic" },
  { value: "World/Europe/Eastern", label: "World/Europe/Eastern" },
  { value: "World/Europe/French", label: "World/Europe/French" },
  { value: "World/Europe/German", label: "World/Europe/German" },
  { value: "World/Europe/Northern", label: "World/Europe/Northern" },
  { value: "World/Europe/Southern", label: "World/Europe/Southern" },
  { value: "World/Europe/Spain", label: "World/Europe/Spain" },
  { value: "World/Europe/Western", label: "World/Europe/Western" },
  { value: "World/Mediterranean/Greece", label: "World/Mediterranean/Greece" },
  { value: "World/Mediterranean/Italy", label: "World/Mediterranean/Italy" },
  { value: "World/Mediterranean/Spain", label: "World/Mediterranean/Spain" },
  { value: "World/Russian", label: "World/Russian" },
  { value: "World/Worldbeat", label: "World/Worldbeat" },
];

export const contributorsOptions = [
  { value: "performer", label: "performer", index: 1 },
  { value: "producer", label: "producer", index: 2 },
  { value: "remixer", label: "remixer", index: 3 },
  { value: "composer", label: "composer", index: 4 },
  { value: "publisher", label: "publisher", index: 5 },
  { value: "editor", label: "editor", index: 6 },
  { value: "featuring", label: "featuring", index: 7 },
  { value: "with", label: "with", index: 8 },
  { value: "conductor", label: "conductor", index: 9 },
  { value: "arranger", label: "arranger", index: 10 },
  { value: "orchestra", label: "orchestra", index: 11 },
  { value: "lyricist", label: "lyricist", index: 12 },
];

export const languages = [
  {
    value: "AB",
    label: "Abkhaz",
  },
  {
    value: "AA",
    label: "Afar",
  },
  {
    value: "AF",
    label: "Afrikaans",
  },
  {
    value: "AK",
    label: "Akan",
  },
  {
    value: "SQ",
    label: "Albanian",
  },
  {
    value: "AM",
    label: "Amharic",
  },
  {
    value: "AR",
    label: "Arabic",
  },
  {
    value: "AN",
    label: "Aragonese",
  },
  {
    value: "HY",
    label: "Armenian",
  },
  {
    value: "AS",
    label: "Assamese",
  },
  {
    value: "AV",
    label: "Avaric",
  },
  {
    value: "AE",
    label: "Avestan",
  },
  {
    value: "AY",
    label: "Aymara",
  },
  {
    value: "AZ",
    label: "Azerbaijani",
  },
  {
    value: "BM",
    label: "Bambara",
  },
  {
    value: "BA",
    label: "Bashkir",
  },
  {
    value: "EU",
    label: "Basque",
  },
  {
    value: "BE",
    label: "Belarusian",
  },
  {
    value: "BN",
    label: "Bengali (Bangla)",
  },
  {
    value: "BH",
    label: "Bihari",
  },
  {
    value: "BI",
    label: "Bislama",
  },
  {
    value: "BS",
    label: "Bosnian",
  },
  {
    value: "BR",
    label: "Breton",
  },
  {
    value: "BG",
    label: "Bulgarian",
  },
  {
    value: "MY",
    label: "Burmese",
  },
  {
    value: "CA",
    label: "Catalan (Valencian)",
  },
  {
    value: "CH",
    label: "Chamorro",
  },
  {
    value: "CE",
    label: "Chechen",
  },
  {
    value: "NY",
    label: "Chichewa; Chewa; Nyanja",
  },
  {
    value: "ZH",
    label: "Chinese",
  },
  {
    value: "CV",
    label: "Chuvash",
  },
  {
    value: "KW",
    label: "Cornish",
  },
  {
    value: "CO",
    label: "Corsican",
  },
  {
    value: "CR",
    label: "Cree",
  },
  {
    value: "HR",
    label: "Croatian",
  },
  {
    value: "CS",
    label: "Czech",
  },
  {
    value: "DA",
    label: "Danish",
  },
  {
    value: "DV",
    label: "Divehi; Dhivehi; Maldivian;",
  },
  {
    value: "NL",
    label: "Dutch",
  },
  {
    value: "DZ",
    label: "Dzongkha",
  },
  {
    value: "EN",
    label: "English",
  },
  {
    value: "EO",
    label: "Esperanto",
  },
  {
    value: "ET",
    label: "Estonian",
  },
  {
    value: "EE",
    label: "Ewe",
  },
  {
    value: "FO",
    label: "Faroese",
  },
  {
    value: "FJ",
    label: "Fijian",
  },
  {
    value: "FI",
    label: "Finnish",
  },
  {
    value: "FR",
    label: "French",
  },
  {
    value: "FF",
    label: "Fula; Fulah; Pulaar; Pular",
  },
  {
    value: "GL",
    label: "Galician",
  },
  {
    value: "KA",
    label: "Georgian",
  },
  {
    value: "DE",
    label: "German",
  },
  {
    value: "EL",
    label: "Greek, Modern",
  },
  {
    value: "GN",
    label: "Guaraní­",
  },
  {
    value: "GU",
    label: "Gujarati",
  },
  {
    value: "HT",
    label: "Haitian; Haitian Creole",
  },
  {
    value: "HA",
    label: "Hausa",
  },
  {
    value: "HE",
    label: "Hebrew",
  },
  {
    value: "HZ",
    label: "Herero",
  },
  {
    value: "HI",
    label: "Hindi",
  },
  {
    value: "HO",
    label: "Hiri Motu",
  },
  {
    value: "HU",
    label: "Hungarian",
  },
  {
    value: "IA",
    label: "Interlingua",
  },
  {
    value: "ID",
    label: "Indonesian",
  },
  {
    value: "IE",
    label: "Interlingue",
  },
  {
    value: "GA",
    label: "Irish",
  },
  {
    value: "IG",
    label: "Igbo",
  },
  {
    value: "IK",
    label: "Inupiaq",
  },
  {
    value: "IO",
    label: "Ido",
  },
  {
    value: "IS",
    label: "Icelandic",
  },
  {
    value: "IT",
    label: "Italian",
  },
  {
    value: "IU",
    label: "Inuktitut",
  },
  {
    value: "JA",
    label: "Japanese",
  },
  {
    value: "JV",
    label: "Javanese",
  },
  {
    value: "KL",
    label: "Kalaallisut, Greenlandic",
  },
  {
    value: "KN",
    label: "Kannada",
  },
  {
    value: "KR",
    label: "Kanuri",
  },
  {
    value: "KS",
    label: "Kashmiri",
  },
  {
    value: "KK",
    label: "Kazakh",
  },
  {
    value: "KM",
    label: "Khmer",
  },
  {
    value: "KI",
    label: "Kikuyu, Gikuyu",
  },
  {
    value: "RW",
    label: "Kinyarwanda",
  },
  {
    value: "KY",
    label: "Kyrgyz",
  },
  {
    value: "KV",
    label: "Komi",
  },
  {
    value: "KG",
    label: "Kongo",
  },
  {
    value: "KO",
    label: "Korean",
  },
  {
    value: "KU",
    label: "Kurdish",
  },
  {
    value: "KJ",
    label: "Kwanyama, Kuanyama",
  },
  {
    value: "LA",
    label: "Latin",
  },
  {
    value: "LB",
    label: "Luxembourgish, Letzeburgesch",
  },
  {
    value: "LG",
    label: "Ganda",
  },
  {
    value: "LI",
    label: "Limburgish, Limburgan, Limburger",
  },
  {
    value: "LN",
    label: "Lingala",
  },
  {
    value: "LO",
    label: "Lao",
  },
  {
    value: "LT",
    label: "Lithuanian",
  },
  {
    value: "LU",
    label: "Luba-Katanga",
  },
  {
    value: "LV",
    label: "Latvian",
  },
  {
    value: "GV",
    label: "Manx",
  },
  {
    value: "MK",
    label: "Macedonian",
  },
  {
    value: "MG",
    label: "Malagasy",
  },
  {
    value: "MS",
    label: "Malay",
  },
  {
    value: "ML",
    label: "Malayalam",
  },
  {
    value: "MT",
    label: "Maltese",
  },
  {
    value: "MI",
    label: "Māori",
  },
  {
    value: "MR",
    label: "Marathi (मराठी भाषा)",
  },
  {
    value: "MH",
    label: "Marshallese",
  },
  {
    value: "MN",
    label: "Mongolian",
  },
  {
    value: "NA",
    label: "Nauru",
  },
  {
    value: "NV",
    label: "Navajo, Navaho",
  },
  {
    value: "NB",
    label: "Norwegian Bokmål",
  },
  {
    value: "ND",
    label: "North Ndebele",
  },
  {
    value: "NE",
    label: "Nepali",
  },
  {
    value: "NG",
    label: "Ndonga",
  },
  {
    value: "NN",
    label: "Norwegian Nynorsk",
  },
  {
    value: "NO",
    label: "Norwegian",
  },
  {
    value: "II",
    label: "Nuosu",
  },
  {
    value: "NR",
    label: "South Ndebele",
  },
  {
    value: "OC",
    label: "Occitan",
  },
  {
    value: "OJ",
    label: "Ojibwe, Ojibwa",
  },
  {
    value: "CU",
    label:
      "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
  },
  {
    value: "OM",
    label: "Oromo",
  },
  {
    value: "OR",
    label: "Oriya",
  },
  {
    value: "OS",
    label: "Ossetian, Ossetic",
  },
  {
    value: "PA",
    label: "Panjabi, Punjabi",
  },
  {
    value: "PI",
    label: "PÄli",
  },
  {
    value: "FA",
    label: "Persian (Farsi)",
  },
  {
    value: "PL",
    label: "Polish",
  },
  {
    value: "PS",
    label: "Pashto, Pushto",
  },
  {
    value: "PT",
    label: "Portuguese",
  },
  {
    value: "QU",
    label: "Quechua",
  },
  {
    value: "RM",
    label: "Romansh",
  },
  {
    value: "RN",
    label: "Kirundi",
  },
  {
    value: "RO",
    label: "Romanian",
  },
  {
    value: "RU",
    label: "Russian",
  },
  {
    value: "SA",
    label: "Sanskrit",
  },
  {
    value: "SC",
    label: "Sardinian",
  },
  {
    value: "SD",
    label: "Sindhi",
  },
  {
    value: "SE",
    label: "Northern Sami",
  },
  {
    value: "SM",
    label: "Samoan",
  },
  {
    value: "SG",
    label: "Sango",
  },
  {
    value: "SR",
    label: "Serbian",
  },
  {
    value: "GD",
    label: "Scottish Gaelic; Gaelic",
  },
  {
    value: "SN",
    label: "Shona",
  },
  {
    value: "SI",
    label: "Sinhala, Sinhalese",
  },
  {
    value: "SK",
    label: "Slovak",
  },
  {
    value: "SL",
    label: "Slovene",
  },
  {
    value: "SO",
    label: "Somali",
  },
  {
    value: "ST",
    label: "Southern Sotho",
  },
  {
    value: "AZ",
    label: "South Azerbaijani",
  },
  {
    value: "ES",
    label: "Spanish",
  },
  {
    value: "SU",
    label: "Sundanese",
  },
  {
    value: "SW",
    label: "Swahili",
  },
  {
    value: "SS",
    label: "Swati",
  },
  {
    value: "SV",
    label: "Swedish",
  },
  {
    value: "TA",
    label: "Tamil",
  },
  {
    value: "TE",
    label: "Telugu",
  },
  {
    value: "TG",
    label: "Tajik",
  },
  {
    value: "TH",
    label: "Thai",
  },
  {
    value: "TI",
    label: "Tigrinya",
  },
  {
    value: "BO",
    label: "Tibetan Standard, Tibetan, Central",
  },
  {
    value: "TK",
    label: "Turkmen",
  },
  {
    value: "TL",
    label: "Tagalog",
  },
  {
    value: "TN",
    label: "Tswana",
  },
  {
    value: "TO",
    label: "Tonga (Tonga Islands)",
  },
  {
    value: "TR",
    label: "Turkish",
  },
  {
    value: "TS",
    label: "Tsonga",
  },
  {
    value: "TT",
    label: "Tatar",
  },
  {
    value: "TW",
    label: "Twi",
  },
  {
    value: "TY",
    label: "Tahitian",
  },
  {
    value: "UG",
    label: "Uyghur, Uighur",
  },
  {
    value: "UK",
    label: "Ukrainian",
  },
  {
    value: "UR",
    label: "Urdu",
  },
  {
    value: "UZ",
    label: "Uzbek",
  },
  {
    value: "VE",
    label: "Venda",
  },
  {
    value: "VI",
    label: "Vietnamese",
  },
  {
    value: "VO",
    label: "VolapÃ¼k",
  },
  {
    value: "WA",
    label: "Walloon",
  },
  {
    value: "CY",
    label: "Welsh",
  },
  {
    value: "WO",
    label: "Wolof",
  },
  {
    value: "FY",
    label: "Western Frisian",
  },
  {
    value: "XH",
    label: "Xhosa",
  },
  {
    value: "YI",
    label: "Yiddish",
  },
  {
    value: "YO",
    label: "Yoruba",
  },
  {
    value: "ZA",
    label: "Zhuang, Chuang",
  },
  {
    value: "ZU",
    label: "Zulu",
  },
];

export const explicitLyrics = [
  { value: "yes", label: "yes" },
  { value: "no", label: "no" },
];

export const licenseTypes = [
  { value: "(c)", label: "copyright" },
  { value: "cc", label: "creative commons" },
];

export const licenseInfoTypes = [
  { value: "BY", label: "Attribution alone" },
  { value: "BY-SA", label: "Attribution + ShareAlike" },
  { value: "BY-NC", label: "Attribution + Noncommercial" },
  { value: "BY-ND", label: "Attribution + NoDerivatives" },
  { value: "BY-NC-SA", label: "Attribution + Noncommercial + ShareAlike" },
  { value: "BY-NC-ND", label: "Attribution + Noncommercial + NoDerivatives" },
];
