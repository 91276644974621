import React, { useMemo } from "react";

import mockData from "./mockMapData";

// pckgs
import { groupBy } from "lodash";

// charts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import highchartsMap from "highcharts/modules/map";
import map from "@highcharts/map-collection/custom/world-palestine-highres.geo.json";

import { countriesMap } from "../config";
import { getTotal } from "./lib/total_calc";
import { applyAggregation } from "./lib/aggregations";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import TableView from "../../../../screens/Admin/Releases/components/TableView";
import { GlobalFilter } from "../../../admin/ReleasesAdmin";

highchartsMap(Highcharts);
const INITIAL_PAGE_SIZE = 25;

function CountryAggregation({
  newUsersSalesData,
  loading,
  isAdmin,
  tableClassName = "my-earnings-section-1-table",
}) {
  // total values calculations
  let totalNewSales = getTotal(newUsersSalesData, "totalNetPayable");
  let totalNewStreams = getTotal(newUsersSalesData, "totalStreams");
  let totalFee = getTotal(newUsersSalesData, "totalFee");

  const formatNumber = new Intl.NumberFormat().format;

  if (totalNewSales) totalNewSales = totalNewSales?.toFixed(2);
  if (totalFee) totalFee = totalFee?.toFixed(2);
  
  let chartData = newUsersSalesData.map((country) => {
    return [country._id.toLowerCase(), country.totalNetPayable];
  });

  let chartOptions = {
    title: {
      text: "",
    },
    plotOptions: {
      map: {
        states: {
          hover: {
            color: "#EEDD66",
          },
        },
        borderWidth: "0.1",
        borderColor: "white",
        nullColor: "#1d2025",
      },
    },
    chart: {
      backgroundColor: "#1d2025",
    },
    colorAxis: {
      min: 0,
      minColor: "#8e8f92",
      maxColor: "#0ef1db",
    },
    legend: {
      layout: "horizontal",
      align: "left",
      verticalAlign: "top",
      title: {
        text: "net total € / country",
        style: {
          color: "white",
          fontSize: "0.7rem",
          fontWeight: "100",
        },
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: "#1d2025",
      borderRadius: 10,
      style: {
        zIndex: 9999,
        color: "white",
        opacity: 0.5,
      },
      shared: true,
      xDateFormat: "%A, %b %e",
      //   valueSuffix: ` ${serviceText}`,
      formatter: function () {
        return "<b>" + this?.point?.name + "</b><br/> € " + this?.point?.value;
      },
    },
    subtitle: {
      floating: true,
      align: "right",
      y: 50,
      style: {
        fontSize: "16px",
      },
    },
    series: [
      {
        mapData: map,
        data: chartData,
        dataLabels: {
          enabled: false,
          format: "{point.name}",
        },
      },
    ],
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },
  };

  const columns = useMemo(
    () =>
      [
        {
          Header: "Country",
          accessor: "_id",
          Cell: ({ value }) => {
            return countriesMap[value] ?? +value;
          },
        },
        {
          Header: "Streams",
          accessor: "totalStreams",
          Cell: ({ value }) => {
            return formatNumber(value ?? 0);
          },
        },

        {
          Header: "Revenue",
          accessor: "totalNetPayable",
          Cell: ({ value }) => {
            return <td title={value || 0}>€ {value?.toFixed(2) ?? 0}</td>;
          },
        },
      ].filter(Boolean),
    [isAdmin]
  );

  const tableInstance = useTable(
    {
      columns,
      data: newUsersSalesData ?? [],
      initialState: {
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    state: { globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        constructorType={"mapChart"}
      />
      <br />
      <div className={"admin-releases mt-4 pb-3 "}>
        <div className="d-block mb-3" style={{ zIndex: 1 }}>
          <p className="font-weight-bold text-white mb-0">Filter by:</p>
          <div className="row pb-2 align-items-center justify-content-between">
            <div className="col-12 col-md">
              <div className="row">
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </div>
            </div>
          </div>
        </div>
        <TableView
          tableInstance={tableInstance}
          loading={loading}
          totalNewStreams={totalNewStreams}
          totalNewSales={totalNewSales}
          salesData={true}
        />
      </div>
    </div>
  );
}

export default CountryAggregation;
