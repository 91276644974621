import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

function ContributorsFieldComponent({
  i,
  SelectComponent,
  contributorsOptions,
  InputComponent,
  contributorsArray,
  trash,
  inputChangeCallback,
  removeInputCallback,
  handleAddContributor,
  prepopulated,
  inputBlurCallback,
  selectBlurCallback,
}) {
  function handleSelectChange(e) {
    let allowedValues = contributorsOptions.map((option) => option.value);
    if (allowedValues.includes(e))
      inputChangeCallback(e, "#participants", "type", i, "contributors");
  }

  function handleInputChange(e) {
    inputChangeCallback(e, "#participants", "value", i, "contributors");
  }

  return (
    <div
      className={
        "nrContributorsWrapper" +
        (i !== 0 ? " nrContributorsWrapperExtended" : "")
      }
      id={"contributorsWrapper" + i}
    >
      <div className="inputUpperComment iuc3 playtreksSelector">
        Contributor(s)
      </div>
      <SelectComponent
        value={useSelector(
          (state) =>
            state.distributionReducer?.releaseForm?.["#participants"]
              ?.contributors?.[i]?.type
        )}
        placeholder="Select role"
        inputGroupClassName="nrContributorsSelectGroup"
        shortInput={true}
        valuePaddingTop={true}
        placeholderTop="60%"
        placeholderFont="Inter-Medium"
        placeholderColor="white"
        placeholderOpacity="1"
        options={contributorsOptions}
        selectChangeCallback={handleSelectChange} // to be used for data transfer
        selectBlurCallback={selectBlurCallback} // leave empty
        databaseField="#participants"
        databaseKey={"contributors"}
        id={i}
      />

      <InputComponent
        value={useSelector(
          (state) =>
            state.distributionReducer?.releaseForm?.["#participants"]
              ?.contributors?.[i]?.value
        )}
        inputGroupClassName={
          "nrContributorsInputGroup nrInputGroup" +
          (i < contributorsArray.length - 1 && contributorsArray.length > 1
            ? " nrcigExtended"
            : "")
        }
        inputClassName={(prepopulated ? "interMediumInput" : "") + " nrInput"}
        addon={i === 0 ? false : "right"}
        inputGroupTextClassName="nrRightAddon"
        img={trash}
        imgClass="nrAddonRightIcon"
        changeCallback={handleInputChange}
        inputBlurCallback={inputBlurCallback}
        addonClickCallback={removeInputCallback}
        id={i}
        databaseField="#participants"
        for="contributors"
        databaseKey={"contributors"}
      />
      {i === contributorsArray.length - 1 ? (
        <div className="nrPlus" onClick={handleAddContributor}>
          +
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ContributorsFieldComponent;
