import React, { useMemo } from "react";
import moment from "moment";
import { monthMap } from "../config";
import ColumnStockChart from "../../../HighCharts/ColumnStockChart";
import { getTotal } from "./lib/total_calc";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import TableView from "../../../../screens/Admin/Releases/components/TableView";
import { GlobalFilter } from "../../../admin/ReleasesAdmin";

const INITIAL_PAGE_SIZE = 50;

function DateRangeAggregation({
  newUsersSalesData,
  loading,
  isAdmin,
  tableClassName = "my-earnings-section-1-table",
}) {
  let singleChartData = newUsersSalesData?.map((sale) => {
    return [
      Number(
        moment
          .utc(`${monthMap[sale._id.month]} ${sale._id.year}`, "MMM YYYY")
          .format("x")
      ),
      sale.totalNetPayable,
    ];
  });

  // NOTE: chart data has to be doubled for column AND spline charts to appear on top of each other
  let chartData = [
    {
      data: singleChartData,
    },
  ];

  // total values calculations
  let totalNewSales = getTotal(newUsersSalesData, "totalNetPayable");
  let totalNewStreams = getTotal(newUsersSalesData, "totalStreams");
  let totalFee;
  
  const formatNumber = new Intl.NumberFormat().format;
  if (totalNewSales) totalNewSales = totalNewSales?.toFixed(2);

  let columnObject = [];
  if (isAdmin) {
    totalFee = getTotal(newUsersSalesData, "totalFee");
    if (totalFee) totalFee = totalFee?.toFixed(2);
    columnObject = [
      {
        Header: "period",
        accessor: "_id",
        Cell: ({ value }) => {
          return `${value.year}-${`${value.month}`.padStart(2, "0")}`;
        },
      },
      {
        Header: "Streams",
        accessor: "totalStreams",
        Cell: ({ value }) => {
          return formatNumber(value ?? 0);
        },
      },
      {
        Header: "Revenue",
        accessor: "totalNetPayable",
        Cell: ({ value }) => {
          return <td title={value || 0}>€ {value?.toFixed(2) ?? 0}</td>;
        },
      },
      {
        Header: "Fee",
        accessor: "totalFee",
        Cell: ({ value }) => {
          return <td title={value || 0}>€ {value?.toFixed(2) ?? 0}</td>;
        },
      },
    ];
  } else {
    columnObject = [
      {
        Header: "period",
        accessor: "_id",
        Cell: ({ value }) => {
          return `${value.year}-${`${value.month}`.padStart(2, "0")}`;
        },
      },
      {
        Header: "Streams",
        accessor: "totalStreams",
        Cell: ({ value }) => {
          return formatNumber(value ?? 0);
        },
      },
      {
        Header: "Revenue",
        accessor: "totalNetPayable",
        Cell: ({ value }) => {
          return <td title={value || 0}>€ {value?.toFixed(2) ?? 0}</td>;
        },
      },
    ];
  }
  const columns = useMemo(
    () =>
      columnObject.filter(Boolean),
    [isAdmin]
  );

  const tableInstance = useTable(
    {
      columns,
      data: newUsersSalesData ?? [],
      initialState: {
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    state: { globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;

  return (
    <>
      <ColumnStockChart
        data={chartData}
        xLabelsFormat="{value:%b '%y}"
        yAxisValuePrefix="€ "
        chartButtonsConfig={[]}
      />
      {/* NOTE: %B for full month name, %Y for full year */}
      <br />
      <div className={"admin-releases mt-4 pb-3 "}>
        <div className="d-block mb-3" style={{ zIndex: 1 }}>
          <p className="font-weight-bold text-white mb-0">Filter by:</p>
          <div className="row pb-2 align-items-center justify-content-between">
            <div className="col-12 col-md">
              <div className="row">
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </div>
            </div>
          </div>
        </div>
        <TableView
          tableInstance={tableInstance}
          loading={loading}
          totalNewStreams={totalNewStreams}
          totalNewSales={totalNewSales}
          totalFee={totalFee}
          salesData={true}
        />
      </div>
    </>
  );
}

export default DateRangeAggregation;
